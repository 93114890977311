<template>
    <div class="body-container">
        <img src="/images/login-back.png" alt="">
        <div class="login-box">
            <img src="/images/logo.png" alt="" style="margin-bottom: 46px;">
            <div class="id-pwd-box" style="margin-bottom: 14px;">
                <input type="text" class="id-pwd-input" v-model="loginId">
                <div class="id-pwd-title">
                    <img src="/images/login-id-icon.png" alt="">
                    ID
                </div>
            </div>
            <div class="id-pwd-box">
                <input type="password" class="id-pwd-input" v-model="loginPwd" @keyup.enter="onClickLogin()">
                <div class="id-pwd-title">
                    <img src="/images/login-pwd-icon.png" alt="">
                    Password
                </div>
            </div>
            <input type="button" class="id-pwd-btn" value="로그인" @click="onClickLogin()">
            <div class="id-pwd-find">아이디/비밀번호 찾기</div>
            <!-- <div class="id-pwd-find">* 아이디 비밀번호는 문의주시기 바랍니다.</div>
            <div class="id-pwd-find">* 053-963-9063</div> -->
        </div>
        <div class="login-bottom-box">
            Copyright (C) Province of Gyeongsangbuk-do. All Rights Reserved.
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    components: {

    },
    setup() {
        const store = useStore();
        const router = useRouter();

        // screen width: 1007, height: 601

        const loginId = ref("");
        const loginPwd = ref("");

        const onClickLogin = async () => {
            const ret = await store.dispatch("auth/AUTH_REQUEST", {
                login_id: loginId.value,
                password: loginPwd.value,
            });

            if (ret) {
                router.push({name: "HomeView"});
            } else {
                alert("아이디 비밀번호를 확인해주세요.");
            }
        }

        return {
            loginId, loginPwd,

            onClickLogin,
        }
    }
}
</script>

<style scoped>
    .body-container {
        width: 100%;
        height: 100vh;
        background-color: #fff;
    }
    .body-container > img {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .login-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 621px;
        height: 546px;
        background-color: #fff;
        border: 1px solid rgba(72, 89, 113, 0.27);
        border-radius: 20px;
        box-shadow: 0px 10px 15px 2px rgba(92, 110, 123, 0.27);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 60px;
        padding-bottom: 70px;
    }
    .login-box > img {
        width: 195px;
        height: 56px;
        object-fit: cover;
    }
    .id-pwd-box {
        width: 440px;
        height: 67px;
        border-radius: 9px;
        position: relative;
        background-color: #f0f1f8;
        border: 1px solid rgba(72, 89, 113, 0.12);
    }
    .id-pwd-input {
        width: 100%;
        height: 100%;
        border-radius: 9px;
        background-color: #f0f1f8;
        border: 1px solid rgba(72, 89, 113, 0.12);
        padding-left: 150px;
    }
    .id-pwd-btn {
        width: 440px;
        height: 67px;
        border-radius: 9px;
        background-color: #014099;
        box-shadow: 0px 2px 10px 1px rgba(92, 110, 123, 0.27);
        font-family: "PreBold";
        font-size: 20px;
        color: #fff;
        margin: 37px 0;
    }
    .id-pwd-title {
        width: 117px;
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        left: 20px;
        top: 0;
        font-family: "PreRegular";
        font-size: 18px;
        color: #a4acb7;
        /* top: 50%; */
        /* transform: translate(0, -50%); */
        /* border-right: 1px solid rgba(72, 89, 113, 0.12); */
    }
    .id-pwd-title > img {
        width: 20px;
        height: 20px;
        object-fit: cover;
        margin-right: 14px;
    }
    .id-pwd-find {
        cursor: pointer;
        font-family: "PreRegular";
        font-size: 16px;
        color: rgba(51, 94, 119, 0.49);
    }
    .login-bottom-box {
        width: 100%;
        height: 50px;
        background-color: #254160;
        position: absolute;
        bottom: 0;
        left: 0;
        font-family: "PreRegular";
        font-size: 13px;
        color: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 1100px) {
        .login-box {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 332px;
            height: 372px;
            background-color: #fff;
            border: 1px solid rgba(72, 89, 113, 0.27);
            border-radius: 20px;
            box-shadow: 0px 10px 15px 2px rgba(92, 110, 123, 0.27);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 27px;
            padding-bottom: 33px;
        }
        .login-box > img {
            width: 123px;
            height: 35px;
            object-fit: cover;
        }
        .id-pwd-box {
            width: 296px;
            height: 55px;
            border-radius: 9px;
            position: relative;
            background-color: #f0f1f8;
            border: 1px solid rgba(72, 89, 113, 0.12);
        }
        .id-pwd-input {
            width: 100%;
            height: 100%;
            border-radius: 9px;
            background-color: #f0f1f8;
            border: 1px solid rgba(72, 89, 113, 0.12);
            padding-left: 130px;
        }
        .id-pwd-btn {
            width: 296px;
            height: 57px;
            border-radius: 9px;
            background-color: #014099;
            box-shadow: 0px 2px 10px 1px rgba(92, 110, 123, 0.27);
            font-family: "PreBold";
            font-size: 15px;
            color: #fff;
            margin: 18px 0;
        }
        .id-pwd-title {
            width: 117px;
            height: 100%;
            display: flex;
            align-items: center;
            position: absolute;
            left: 20px;
            top: 0;
            font-family: "PreRegular";
            font-size: 15px;
            color: #a4acb7;
            /* top: 50%; */
            /* transform: translate(0, -50%); */
            /* border-right: 1px solid rgba(72, 89, 113, 0.12); */
        }
        .id-pwd-title > img {
            width: 18px;
            height: 18px;
            object-fit: cover;
            margin-right: 14px;
        }
        .id-pwd-find {
            cursor: pointer;
            font-family: "PreRegular";
            font-size: 16px;
            color: rgba(51, 94, 119, 0.49);
        }
        .login-bottom-box {
            width: 100%;
            height: 38px;
            background-color: #254160;
            position: fixed;
            bottom: 0;
            left: 0;
            font-family: "PreRegular";
            font-size: 10px;
            color: rgba(255, 255, 255, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>