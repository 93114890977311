<template>
    <div class="default-navbar">
        <div class="default-navbar-items">
            <div class="logo-box" @click="onClickLogo()">
                <img src="/images/logo.png" alt="">
            </div>
            <div class="navbar-title">
                경상북도 수산자원연구원
            </div>
            <div class="navbar-line"></div>
            <div class="navbar-content">
                연어 생산 및 사육일지 관리 시스템
            </div>
        </div>
        <div v-if="!bShowMob" class="default-navbar-items">
            <div @click="onClickMenu(0)" :class="navbarMenuClass(0)">사육일지</div>
            <div @click="onClickMenu(1)" :class="navbarMenuClass(1)">실측관리</div>
        </div>
        <div v-if="!bShowMob" class="default-navbar-items">
            <div class="welcome-user">
                <span>{{ userName }}</span>님 환영합니다!
            </div>
            <input type="button" class="logout-btn" value="로그아웃"
            @click="onClickLogout()">
        </div>
        <div v-if="bShowMob">
            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasRightLabel">
                    <div class="welcome-user-mob">
                        <span>{{ userName }}</span> 님 환영합니다!
                        <input type="button" class="logout-btn" value="로그아웃"
                        @click="onClickLogout()">
                    </div>
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div class="default-navbar-items-mob">
                    <div @click="onClickMenu(0)" :class="navbarMobMenuClass(0)">사육일지</div>
                    <div @click="onClickMenu(1)" :class="navbarMobMenuClass(1)">실측관리</div>
                </div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, onUnmounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    setup() {
        const store = useStore();
        const router = useRouter();

        const userName = ref(localStorage.getItem('user-name'));

        const bShowMob = ref(false);
        const screenSize = reactive({
            screenWidth: 0,
            screenHiehgt: 0,
        });

        const selectedNavbarMenuIndex = ref(0);

        onMounted(() => {
            updateScreenSize();
            window.addEventListener('resize', updateScreenSize);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateScreenSize);
        });


        const navbarMenuClass = (menuIndex) => {
            if (selectedNavbarMenuIndex.value === menuIndex) {
                return "navbar-menu-s";
            } else {
                return "navbar-menu";
            }
        }

        const navbarMobMenuClass = (menuIndex) => {
            if (selectedNavbarMenuIndex.value === menuIndex) {
                return "navbar-menu-mob-s";
            } else {
                return "navbar-menu-mob";
            }
        }

        const onClickMenu = (menuIndex) => {
            selectedNavbarMenuIndex.value = menuIndex;
            if (menuIndex === 0) {
                router.push({name: "HomeView"});
            } else if (menuIndex === 1) {
                selectedNavbarMenuIndex.value = 0;
                alert("준비중입니다.");
                return;
            }
        }

        // 로그아웃
        const onClickLogout = async () => {
            const ret = await store.dispatch('auth/AUTH_LOGOUT');
            if (ret) {
                router.push({name: "Login"});
            } else {
                console.log("로그아웃 실패");
            }
        }

        const updateScreenSize = () => {
            screenSize.screenWidth = window.innerWidth;
            screenSize.screenHiehgt = window.innerHeight;
            mobileSize(screenSize.screenWidth);
        }

        const mobileSize = (sSizeWidth) => {
            if (sSizeWidth < 1000) {
                bShowMob.value = true;
            } else {
                bShowMob.value = false;
            }
        }

        // 로고 클릭 이벤트
        const onClickLogo = () => {
            router.push({name: "HomeView"});
        }

        return {
            userName, bShowMob,

            navbarMenuClass, onClickMenu, onClickLogout, navbarMobMenuClass,
            onClickLogo,
        }
    }
}
</script>

<style scoped>
    .default-navbar {
        width: 100%;
        height: 70px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 44px;
        padding-right: 23px;
    }
    .default-navbar-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    .logo-box {
        width: 133px;
        height: 40px;
        margin-right: 16px;
    }
    .logo-box > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .navbar-title {
        width: 191px;
        font-family: "PreBold";
        font-size: 20px;
        letter-spacing: -0.5px;
        color: #353B55;
    }
    .navbar-line {
        width: 1px;
        height: 16px;
        background-color: #e0e3f0;
        margin: 0 13px;
    }
    .navbar-content {
        width: 258px;
        font-family: "PreBold";
        font-size: 20px;
        letter-spacing: -0.5px;
        color: #014099;
    }
    .welcome-user {
        width: 178px;
        font-family: "PreLight";
        font-size: 16px;
        color: #000;
    }
    .welcome-user > span {
        font-family: "PreBold";
        font-size: 16px;
        color: #000;
    }
    .logout-btn {
        width: 84px;
        height: 30px;
        border-radius: 4px;
        background-color: #fe3f60;
        color: #fff;
        border: none;
        margin-left: 18px;
        font-family: "PreMedium";
        font-size: 14px;
    }
    .navbar-menu {
        min-width: 50px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "PreRegular";
        font-size: 18px;
        letter-spacing: -0.5px;
        color: #254160;
        border-bottom: 4px solid transparent;
        margin: 0 25px;
        cursor: pointer;
    }
    .navbar-menu-s {
        min-width: 50px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "PreBold";
        font-size: 18px;
        letter-spacing: -0.5px;
        color: #014099;
        border-bottom: 4px solid #014099;
        margin: 0 25px;
        cursor: pointer;
    }
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg' width='30' height='30'%3e%3cpath stroke='rgba(1,64,153,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }

    .default-navbar-items-mob {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }

    .navbar-menu-mob-s {
        min-width: 100px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "PreBold";
        font-size: 18px;
        letter-spacing: -0.5px;
        color: #014099;
        border-bottom: 4px solid #014099;
        margin: 0 25px;
        cursor: pointer;
    }
    .navbar-menu-mob {
        min-width: 100px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "PreRegular";
        font-size: 18px;
        letter-spacing: -0.5px;
        color: #254160;
        border-bottom: 4px solid transparent;
        margin: 0 25px;
        cursor: pointer;
    }
    .offcanvas {
        width: 375px;
        min-height: 100%;
    }

    @media screen and (max-width: 1500px) {
        .default-navbar {
            width: 100%;
            height: 60px;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
        }
        .logo-box {
            width: 100px;
            height: 30px;
            margin-right: 16px;
        }
        .logo-box > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .navbar-title {
            width: 143px;
            font-family: "PreBold";
            font-size: 15px;
            letter-spacing: -0.5px;
            color: #353B55;
        }
        .navbar-line {
            width: 1px;
            height: 16px;
            background-color: #e0e3f0;
            margin: 0 13px;
        }
        .navbar-content {
            width: 190px;
            font-family: "PreBold";
            font-size: 15px;
            letter-spacing: -0.5px;
            color: #014099;
            margin-right: 50px;
        }
        .welcome-user {
            width: 148px;
            font-family: "PreLight";
            font-size: 14px;
            color: #000;
            text-align: right;
        }
        .welcome-user > span {
            font-family: "PreBold";
            font-size: 14px;
            color: #000;
        }
        .welcome-user-mob {
            font-family: "PreLight";
            font-size: 16px;
            color: #000;
        }
        .welcome-user-mob > span {
            font-family: "PreBold";
            font-size: 17px;
            color: #000;
        }
        .logout-btn {
            width: 84px;
            height: 30px;
            border-radius: 4px;
            background-color: #fe3f60;
            color: #fff;
            border: none;
            margin-left: 8px;
            font-family: "PreMedium";
            font-size: 12px;
        }
        .navbar-menu {
            min-width: 50px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "PreRegular";
            font-size: 14px;
            letter-spacing: -0.5px;
            color: #254160;
            border-bottom: 4px solid transparent;
            margin: 0 25px;
            cursor: pointer;
            padding-top: 10px;
        }
        .navbar-menu-s {
            min-width: 50px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "PreBold";
            font-size: 14px;
            letter-spacing: -0.5px;
            color: #014099;
            border-bottom: 4px solid #014099;
            margin: 0 25px;
            cursor: pointer;
            padding-top: 10px;
        }
    }

    @media screen and (max-width: 850px) {
        .default-navbar {
            width: 100%;
            height: 70px;
            background-color: #fff;
            display: flex;
            align-items: center;
        }
        .default-navbar-items {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            width: 75%;
        }
        .navbar-line {
            display: none;
        }
        .navbar-content {
            display: none;
        }
    }
</style>

