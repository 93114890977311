import { createApp } from 'vue'
import App from './App.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// vue router
import router from '@/router/router.js';

// day js
import dayjs from 'dayjs';

// v-calendar
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

// vue i18n
import { createI18n } from 'vue-i18n';
import i18n_ko from '@/locales/ko.json';
import i18n_en from '@/locales/en.json';

// store
import store from '@/store/store.js';

const messages = {
    ko: i18n_ko,
    en: i18n_en,
}

let local = localStorage.getItem('locales');
if (local === null) {
    local = 'ko';
    localStorage.setItem('locales', local);
}

const i18n = createI18n({
    locale: local,
    allowComposition: true,
    globalInjection: true,
    fallbackLocale: 'en',
    legacy: false,
    messages
});

const app = createApp(App);
app.use(router)
app.use(i18n)
app.use(store)
app.use(dayjs)
app.use(VCalendar)
app.mount('#app');

