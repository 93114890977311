<template>
    <tr :class="backgroundColorClass(pBackColor)" @click="onClickRecord(pRecord.record_date)">
        <!-- 날짜 -->
        <td rowspan="2" class="blue-font-date">{{ dateFormating(nullProcessing(morningObj.record_date)) }}</td>
        <!-- 날짜 오전 -->
        <td class="blue-font">오전</td>
        <!-- 수조 -->
        <td rowspan="2" style="white-space: nowrap;">{{ nullProcessing(morningObj.cisterns_name) }}</td>
        <!-- 수온(오전) -->
        <td>{{ nullProcessing(morningObj.water_temp) }}</td>
        <!-- 사육수(오전) -->
        <td>{{ nullProcessing(morningObj.fish_cnt) }}</td>
        <td>{{ nullProcessing(morningObj.dead_fish_cnt) }}</td>
        <td>{{ nullProcessing(morningObj.otc) }}</td>
        <td>{{ nullProcessing(morningObj.amoxine) }}</td>
        <td>{{ nullProcessing(morningObj.oxolinic) }}</td>
        <td>{{ nullProcessing(morningObj.nitrite) }}</td>
        <td>{{ nullProcessing(morningObj.nitrate) }}</td>
        <td>{{ nullProcessing(morningObj.ammonia) }}</td>
        <td style="white-space: nowrap;">{{ nullProcessing(morningObj.feed_name) }}</td>
        <td>{{ nullProcessing(morningObj.feed_value) }}</td>
        <td>{{ nullProcessing(morningObj.feed_time) }}</td>
        <td @click="onClickNote(morningObj.id, $event)">
            <template v-if="nullIconProcessing(morningObj.note)">
                <div class="note-container">
                    <img class="note-img" src="/images/note.png" alt="Note Icon">
                    <div :class="['note-visible', { active: selectedNoteId === morningObj.id }]">
                        {{ morningObj.note }}
                    </div>
                </div>
            </template>
            <template v-else>
                -
            </template>
        </td>
        <td>
            <template v-if="nullIconProcessing(morningObj.imgsrc)">
                <img class="gallery-img" src="/images/gallery.png" alt="gallery Icon">
            </template>
            <template v-else>
                -
            </template>
        </td>
    </tr>
    <tr :class="backgroundColorClass(pBackColor)" @click="onClickRecord(pRecord.record_date)">
        <!-- 날짜 오후 -->
        <td class="blue-font">오후</td>
        <!-- 수온(오후) -->
        <td>{{ nullProcessing(afterObj.water_temp) }}</td>
        <!-- 사육수(오후) -->
        <td>{{ nullProcessing(afterObj.fish_cnt) }}</td>
        <td>{{ nullProcessing(afterObj.dead_fish_cnt) }}</td>
        <td>{{ nullProcessing(afterObj.otc) }}</td>
        <td>{{ nullProcessing(afterObj.amoxine) }}</td>
        <td>{{ nullProcessing(afterObj.oxolinic) }}</td>
        <td>{{ nullProcessing(afterObj.nitrite) }}</td>
        <td>{{ nullProcessing(afterObj.nitrate) }}</td>
        <td>{{ nullProcessing(afterObj.ammonia) }}</td>
        <td style="white-space: nowrap;">{{ nullProcessing(afterObj.feed_name) }}</td>
        <td>{{ nullProcessing(afterObj.feed_value) }}</td>
        <td>{{ nullProcessing(afterObj.feed_time) }}</td>
        <td @click="onClickNote(afterObj.id, $event)">
            <template v-if="nullIconProcessing(afterObj.note)">
                <div class="note-container">
                    <img class="note-img" src="/images/note.png" alt="Note Icon">
                    <div :class="['note-visible', { active: selectedNoteId === afterObj.id }]">
                        {{ afterObj.note }}
                    </div>
                </div>
            </template>
            <template v-else>
                -
            </template>
        </td>
        <td>
            <template v-if="nullIconProcessing(afterObj.imgsrc)">
                <img class="gallery-img" src="/images/gallery.png" alt="gallery Icon">
            </template>
            <template v-else>
                -
            </template>
        </td>
    </tr>
</template>

<script>
import dayjs from 'dayjs';
import { ref, watch } from 'vue';


export default {
    components: {
    },
    props: {
        pBackColor: {
            type: Number
        },
        pRecord: {
            type: Object
        },
        pNoteId: {
            type: Number,
        }
    },
    emits: [
        "onNoteSelected", "onClickRecord"
    ],
    setup(props, {emit}) {
        const morningObj = ref(props.pRecord.morning);
        const afterObj = ref(props.pRecord.afternoon);

        const screenWidth = ref(window.outerWidth)

        const initData = () => {
            if (morningObj.value.feed_time) {
                const splitTime = morningObj.value.feed_time.split(':');
                morningObj.value.feed_time = splitTime[0] + ":" + splitTime[1];
                if (morningObj.value.feed_time == "00:00") {
                    morningObj.value.feed_time = "-";
                }
            }
            if (afterObj.value.feed_time) {
                const splitTime = afterObj.value.feed_time.split(':');
                afterObj.value.feed_time = splitTime[0] + ":" + splitTime[1];
                if (afterObj.value.feed_time == "00:00") {
                    afterObj.value.feed_time = "-";
                }
            }
            if (morningObj.value.feed_name) {
                const splitString = morningObj.value.feed_name.split('사료');
                morningObj.value.feed_name = splitString[0] + ' ' + morningObj.value.feed_num;
            }
            if (afterObj.value.feed_name) {
                const splitString = afterObj.value.feed_name.split('사료');
                afterObj.value.feed_name = splitString[0] + ' ' + afterObj.value.feed_num;
            }
        }
        initData();

        const backgroundColorClass = (bIndex) => {
            if (bIndex % 2 === 0) {
                return "back-color-even";
            } else {
                return "back-color-odd"
            }
        }

        // 내용이 아무것도 없을 때 처리
        const nullProcessing = (item) => {
            if (item === undefined || item === null || item === "") {
                return '-'
            } else {
                return item;
            }
        }

        // 내용이 아무것도 없을 때 아이콘 처리
        const nullIconProcessing = (item) => {
            if (item === undefined || item === null || item === "") {
                return false;
            } else {
                return true;
            }
        }

        // 날짜 포멧
        const dateFormating = (rDate) => {
            if (screenWidth.value < 1500) {
                return dayjs(rDate).format('YY.MM.DD');
            } else {
                return dayjs(rDate).format('YYYY-MM-DD');
            }
        }

        const selectedNoteId = ref(null);

        const onClickNote = (recordId, event) => {
            if (selectedNoteId.value === recordId) {
                selectedNoteId.value = null;
                event.stopPropagation();
            } else {
                selectedNoteId.value = recordId;
                emit("onNoteSelected", recordId, event);
            }
        }

        watch(() => props.pNoteId, (cur) => {
            selectedNoteId.value = cur;
        });

        const onClickRecord = (selectedDate) => {
            emit("onClickRecord", selectedDate);
        }

        return {
            morningObj, afterObj, selectedNoteId,

            nullProcessing, nullIconProcessing, onClickNote, backgroundColorClass,
            onClickRecord, dateFormating,
        }
    }
}
</script>

<style scoped>
    th,
    td {
        border: 1px solid #DDDDDD;
        padding: 8px;
        text-align: center;
        font-family: "PreRegular";
        font-size: 16px;
        color: #485971;
    }

    tr {
        cursor: pointer;
    }

    /* 사육환경, 약욕, 수질, 사료급이 셀 너비 고정 */
    th[colspan="3"],
    th[colspan="3"] + th,
    th[colspan="3"] + th + th {
        width: 100px;
    }

    /* 셀 가로 중앙 정렬 */
    th,
    td {
        vertical-align: middle;
    }

    /* 특이사항, 사진 셀 세로 중앙 정렬 */
    th[rowspan="2"],
    td[rowspan="2"] {
        vertical-align: middle;
        width: 100px;
    }

    .blue-font-date {
        font-family: "PreBold";
        font-size: 18px;
        color: #014099;
    }
    .blue-font {
        font-family: "PreBold";
        font-size: 18px;
        color: #014099;
    }

    .note-img {
        width: 23px;
        height: 23px;
        object-fit: cover;
        cursor: pointer;
    }
    .gallery-img {
        width: 27px;
        height: 27px;
        object-fit: cover;
        cursor: pointer;
    }

    .note-container {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .note-visible {
        width: 290px;
        min-height: 120px;
        border-radius: 5px;
        position: absolute;
        left: -260px;
        top: 20px;
        background-color: #FFD29C;
        z-index: 1;
        padding: 17px;
        text-align: left;
        font-family: "PreRegular";
        font-size: 16px;
        color: #7A674F;
        display: none;
        text-overflow: ellipsis;
    }

    .note-visible.active {
        display: block;
    }

    .back-color-even {
        background-color: #fff;
    }

    .back-color-odd {
        background-color: #F5F9FD;
    }

    @media screen and (max-width: 1500px) {
        th,
        td {
            border: 1px solid #DDDDDD;
            padding: 8px;
            text-align: center;
            font-family: "PreRegular";
            font-size: 13px;
            color: #485971;
        }
        .note-img {
            width: 17px;
            height: 17px;
            object-fit: cover;
            cursor: pointer;
        }
        .gallery-img {
            width: 21px;
            height: 21px;
            object-fit: cover;
            cursor: pointer;
        }
        .blue-font-date {
            font-family: "PreRegular";
            font-size: 14px;
            color: #014099;
            white-space: nowrap;
        }
        .blue-font {
            width: 80px;
            font-family: "PreRegular";
            font-size: 14px;
            color: #014099;
            white-space: nowrap;
        }
    }

</style>