<template>
    <div class="gro-write-record">
        <div class="gro-wr-title" :style="{backgroundColor: titleColor()}">
            <div>일지작성 ({{ titleString() }})</div>
            <input type="button" class="gro-wr-title-btn" value="저장" :style="{color: titleColor()}"
            @click="onClickSave()">
        </div>
        <div class="gro-write-content">
            <div class="gro-write-grid">
                <!-- 첫번째박스 (자연수온) -->
                <div class="gro-record-box" 
                :style="{backgroundColor: backColorClass(), borderColor: borderColorClass(), 
                padding: titlePaddingStyle()}">
                    <div class="gro-record-colunm">
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">자연수온<br />
                                <span :style="{color: inputTextColorClass()}">(인입수)</span></div>
                            <input type="number" v-model="waterTemp"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">℃</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">수용량</div>
                            <input type="number" v-model="fishCnt"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">마리</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">폐사량</div>
                            <input type="number" v-model="deadFishCnt"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">마리</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">환수시간</div>
                            <div>
                                <SelectTimeInput :pBorderColor="inputBorderColorclass()"
                                :pTimeValue="cycleTime" :pTimeType="0" @emitNewTime="emitNewTime" />
                            </div>
                        </div>
                        <div class="gro-record-input-chk">
                            <div :style="{color: inputTextColorClass()}">환수여부</div>
                            <label :for="'cycle-chk-' + pRecordType" @change="changeCycle($event)">
                                <input type="checkbox" :id="'cycle-chk-' + pRecordType">
                                &nbsp;환수완료
                            </label>
                        </div>
                    </div>
                </div>
                <!-- 두번째박스 (약품) -->
                <div class="gro-record-box" 
                :style="{backgroundColor: backColorClass(), borderColor: borderColorClass(), 
                padding: titlePaddingStyle()}">
                    <div class="gro-record-colunm">
                        <div class="gro-record-title">약품</div>
                        <div class="gro-record-title-divider" :style="{backgroundColor: inputBorderColorclass()}"></div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">O.T.C</div>
                            <input type="number" v-model="otc"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">ml</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">옥소린산</div>
                            <input type="number" v-model="oxolinic"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">ml</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">암옥신</div>
                            <input type="number" v-model="amoxine"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">ml</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">투입시간</div>
                            <div>
                                <SelectTimeInput :pBorderColor="inputBorderColorclass()"
                                :pTimeValue="drugTime" :pTimeType="1" @emitNewTime="emitNewTime" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 세번째박스 (사료) -->
                <div class="gro-record-box" 
                :style="{backgroundColor: backColorClass(), borderColor: borderColorClass(), 
                padding: titlePaddingStyle()}">
                    <div class="gro-record-colunm">
                        <div class="gro-record-title">사료</div>
                        <div class="gro-record-title-divider" :style="{backgroundColor: inputBorderColorclass()}"></div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">사료종류</div>
                            <div class="gro-record-select-box">
                                <select id="feed-list" :style="{borderColor: inputBorderColorclass()}" 
                                v-model="feedTypeId">
                                    <option v-for="feed in feedList" :key="feed" :value="feed.id">
                                        {{ feed.name }} {{ feed.feed_num }}호
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">급이량</div>
                            <input type="number" v-model="feedValue"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">g</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">급이시간</div>
                            <div>
                                <SelectTimeInput :pBorderColor="inputBorderColorclass()"
                                :pTimeValue="feedTime" :pTimeType="2" @emitNewTime="emitNewTime" />
                            </div>
                        </div>
                        <div class="gro-record-input" style="visibility: hidden;"></div>
                    </div>
                </div>
                <!-- 세번째박스 (수질) -->
                <div class="gro-record-box" 
                :style="{backgroundColor: backColorClass(), borderColor: borderColorClass(), 
                padding: titlePaddingStyle()}">
                    <div class="gro-record-colunm">
                        <div class="gro-record-title">수질</div>
                        <div class="gro-record-title-divider" :style="{backgroundColor: inputBorderColorclass()}"></div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">아질산염</div>
                            <input type="number" v-model="nitrite"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">mg/L</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">질산염</div>
                            <input type="number" v-model="nitrate"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">mg/L</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">암모니아</div>
                            <input type="number" v-model="ammonia"
                            :style="{borderColor: inputBorderColorclass(), color: inputTextColorClass()}">
                            <div :style="{color: inputTextColorClass()}" class="gro-record-unit">mg/L</div>
                        </div>
                        <div class="gro-record-input">
                            <div :style="{color: inputTextColorClass()}">측정시간</div>
                            <div>
                                <SelectTimeInput :pBorderColor="inputBorderColorclass()"
                                :pTimeValue="waterTime" :pTimeType="3" @emitNewTime="emitNewTime" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 특이사항/사진 -->
            <div class="gro-write-right">
                <div>
                    <div :style="{color: textColorClass()}" class="gro-write-title">특이사항</div>
                    <div class="gro-write-textarea-box">
                        <textarea class="gro-write-textarea" v-model="textareaValue" @input="countCharacters"></textarea>
                        <div>{{ writeCnt }}/{{ maxWriteCnt }}자</div>
                    </div>
                </div>
                <div>
                    <div class="gro-write-title-box">
                        <div :style="{color: textColorClass()}" class="gro-write-title">사진</div>
                        <input v-if="bShowImg" type="button" value="삭제" class="img-delete-btn" @click="onClickDeleteImg()">
                        <input v-if="imgsrc" type="button" value="삭제" class="img-delete-btn" @click="onClickDeleteImgsrc()">
                    </div>
                    <div v-if="!bShowImg && !imgsrc" class="gro-write-imgbox" @click="onClickUploadImg()">
                        <input :id="'gro-img-' + pRecordType" class="gro-write-img-file" type="file" @change="handleFileChange"/>
                        <div>
                            <img src="/images/camera.png" alt="">
                            사진을 등록해주세요.
                        </div>
                    </div>
                    <div v-if="bShowImg" class="gro-write-imgbox-img">
                        <img :src="selectedImg" alt="선택된 이미지">
                    </div>
                    <div v-if="imgsrc" class="gro-write-imgbox-img">
                        <img :src="imgsrc" alt="선택된 이미지">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import SelectTimeInput from './datepicker/SelectTimeInput.vue';

export default {
    components: {
        SelectTimeInput,
    },
    props: {
        pRecordType: {
            type: String,
        },
        pRecords: {
            type: Object,
        },
        pFeeds: {
            type: Array,
        },
        pSelectedDate: {
            type: String,
        }
    },
    emits: ["emitSave", "emitDelImg"],
    setup(props, {emit}) {
        const textareaValue = ref('');
        const writeCnt = ref(0);
        const maxWriteCnt = ref(1000);
        const fileInputRef = ref(null);
        const selectedImg = ref(null);
        const selectedFile = ref(null);
        const bShowImg = ref(false);
        const feedList = ref([]);

        const getDomCheck = ref(null);

        // 선택된 record obj
        const recordObj = ref(props.pRecords);

        onMounted(() => {
            getDomCheck.value = document.getElementById("cycle-chk-" + props.pRecordType);
            fileInputRef.value = document.getElementById("gro-img-" + props.pRecordType);
        });

        // feeds list watch(사료종류)
        watch(() => props.pFeeds, (newFeeds) => {
            feedList.value = newFeeds;
        });

        // obj 내용 바뀌면 clear 해줘야함
        watch(() => [props.pRecords, props.pSelectedDate], ([newRecord]) => {
            recordObj.value = newRecord;
            if (recordObj.value === null) {
                clearData();
                return;
            }
            initData(recordObj.value);
            checkboxDefaultValue();
        });

        const waterTemp = ref(""); // 자연수온
        const fishCnt = ref(0); // 수용량
        const deadFishCnt = ref(0); // 폐사량
        const cycleTime = ref("") // 환수시간
        const cycleCheck = ref(0); // 환수여부
        const otc = ref(""); // o.t.c
        const oxolinic = ref(""); // 옥소린산
        const amoxine = ref(""); // 암옥신
        const drugTime = ref(""); // 약품 투입시간
        const feedValue = ref(0); // 급이량
        const feedTime = ref(""); // 급이시간
        const feedTypeId = ref(0); // 사료종류 id
        const nitrite = ref(""); // 아질산염
        const nitrate = ref(""); // 질산염
        const ammonia = ref(""); // 암모니아
        const waterTime = ref(""); // 수질측정시간
        const imgId = ref(0);
        const imgsrc = ref(""); // 이미지 경로

        const initData = (paramData) => {
            const record = paramData;
            bShowImg.value = false;
            // console.log("init data", record);
            if (JSON.stringify(record) === "{}") {
                return;
            }
            waterTemp.value = record.water_temp;
            fishCnt.value = record.fish_cnt;
            deadFishCnt.value = record.dead_fish_cnt;
            cycleTime.value = record.cycle_time;
            cycleCheck.value = record.cycle_check;
            otc.value = record.otc;
            oxolinic.value = record.oxolinic;
            amoxine.value = record.amoxine;
            drugTime.value = record.drug_time;
            feedValue.value = record.feed_value;
            feedTime.value = record.feed_time;
            feedTypeId.value = record.feeds_id;
            nitrite.value = record.nitrite;
            nitrate.value = record.nitrate;
            ammonia.value = record.ammonia;
            waterTime.value = record.water_time;
            textareaValue.value = record.note;
            imgId.value = record.img_id;
            imgsrc.value = record.imgsrc;
            selectedFile.value = null;
        }

        const clearData = () => {
            waterTemp.value = "";
            fishCnt.value = 0;
            deadFishCnt.value = 0;
            cycleTime.value = "";
            cycleCheck.value = 0;
            otc.value = "";
            oxolinic.value = "";
            amoxine.value = "";
            drugTime.value = "";
            feedValue.value = 0;
            feedTime.value = "";
            feedTypeId.value = 0;
            nitrite.value = "";
            nitrate.value = "";
            ammonia.value = "";
            waterTime.value = "";
            textareaValue.value = "";
            imgId.value = 0;
            imgsrc.value = "";
            selectedFile.value = null;
            checkboxDefaultValue();
        }

        const onClickSave = () => {
            let emitRecord = {};

            // obj에 데이터 넣기
            emitRecord.water_temp = String(waterTemp.value);
            emitRecord.fish_cnt = parseInt(fishCnt.value);
            emitRecord.dead_fish_cnt = parseInt(deadFishCnt.value);
            emitRecord.cycle_time = String(cycleTime.value);
            emitRecord.cycle_check = parseInt(cycleCheck.value);
            emitRecord.otc = String(otc.value);
            emitRecord.oxolinic = String(oxolinic.value);
            emitRecord.amoxine = String(amoxine.value);
            emitRecord.drug_time = String(drugTime.value);
            emitRecord.feed_value = parseInt(feedValue.value);
            emitRecord.feed_time = String(feedTime.value);
            emitRecord.feeds_id = parseInt(feedTypeId.value);
            emitRecord.nitrite = String(nitrite.value);
            emitRecord.nitrate = String(nitrate.value);
            emitRecord.ammonia = String(ammonia.value);
            emitRecord.water_time = String(waterTime.value);
            emitRecord.note = String(textareaValue.value);

            emit("emitSave", emitRecord, props.pRecordType, selectedFile.value);
        }

        // checkbox default 값
        const checkboxDefaultValue = () => {
            if (cycleCheck.value === 1) {
                return getDomCheck.value.checked = true;
            } else {
                return getDomCheck.value.checked = false;
            }
        }

        // 새로운 시간값 변경
        const emitNewTime = (newTime, timeType) => {
            if (timeType === 0) {
                cycleTime.value = newTime;
                cycleCheck.value = 1;
                checkboxDefaultValue();
            } else if (timeType === 1) {
                drugTime.value = newTime;
            } else if (timeType === 2) {
                feedTime.value = newTime;
            } else if (timeType === 3) {
                waterTime.value = newTime;
            }
        }

        // 환수여부 체크 이벤트
        const changeCycle = (event) => {
            if (event.target.checked) {
                cycleCheck.value = 1;
            } else {
                cycleCheck.value = 0;
            }
        }

        // 이미지 체인지
        const handleFileChange = (event) => {
            console.log(event.target.files[0]);
            bShowImg.value = true;
            selectedFile.value = event.target.files[0];
            selectedImg.value = URL.createObjectURL(selectedFile.value);
            event.target.value = '';
        }

        // 이미지 업로드 버튼 이벤트
        const onClickUploadImg = () => {
            fileInputRef.value.click();
        }

        // 이미지 삭제 버튼 이벤트
        const onClickDeleteImg = () => {
            selectedFile.value = null;
            selectedImg.value = null;
            bShowImg.value = false;
        }

        // 이미지 src 삭제 이벤트
        const onClickDeleteImgsrc = () => {
            bShowImg.value = false;

            // 이미지 삭제 호출
            if (confirm("이미지를 삭제하시겠습니까?")) {
                emit("emitDelImg", imgId.value);
            }
        }

        // -----------------------------------------------------------------------------------------
        // ------------------------------------- 클래스/스타일 관련 -------------------------------------
        // -----------------------------------------------------------------------------------------
        const titleColor = () => {
            const recordType = props.pRecordType;
            if (recordType === 'mor') {
                return "#EE4361";
            } else if (recordType === 'aft') {
                return "#014099";
            }
        }
        const titleString = () => {
            const recordType = props.pRecordType;
            if (recordType === 'mor') {
                return "오전";
            } else if (recordType === 'aft') {
                return "오후";
            }
        }
        const textColorClass = () => {
            const recordType = props.pRecordType;
            if (recordType === 'mor') {
                return "#4E3535";
            } else if (recordType === 'aft') {
                return "#353B55";
            }
        }
        // 배경색상
        const backColorClass = () => {
            const recordType = props.pRecordType;
            if (recordType === 'mor') {
                return "#F9EEF0";
            } else if (recordType === 'aft') {
                return "#F0F1F8";
            }
        }
        // 배경 border색상
        const borderColorClass = () => {
            const recordType = props.pRecordType;
            if (recordType === 'mor') {
                return "rgba(234, 212, 216, 0.1)";
            } else if (recordType === 'aft') {
                return "rgba(1, 64, 153, 0.1)";
            }
        }
        // input border 색상
        const inputBorderColorclass = () => {
            const recordType = props.pRecordType;
            if (recordType === 'mor') {
                return "rgba(143, 110, 110, 0.24)";
            } else if (recordType === 'aft') {
                return "rgba(33, 88, 166, 0.24)";
            }
        }
        // input text 색상
        const inputTextColorClass = () => {
            const recordType = props.pRecordType;
            if (recordType === 'mor') {
                return "#4E3535";
            } else if (recordType === 'aft') {
                return "#353B55";
            }
        }
        // title 여부에 따른 padding값
        const titlePaddingStyle = () => {
            if (props.bTitle) {
                return "23px";
            } else {
                return "32px";
            }
        }
        // 글자수 계산
        const countCharacters = () => {
            writeCnt.value = textareaValue.value.length;

            if (writeCnt.value > maxWriteCnt.value) {
                textareaValue.value = textareaValue.value.slice(0, maxWriteCnt.value);
                writeCnt.value = maxWriteCnt.value;
            }
        }
        // -----------------------------------------------------------------------------------------
        // ------------------------------------- 클래스/스타일 관련 -------------------------------------
        // -----------------------------------------------------------------------------------------

        return {
            writeCnt, maxWriteCnt, textareaValue,
            waterTemp, fishCnt, deadFishCnt, cycleTime, cycleCheck, otc, oxolinic, amoxine, 
            drugTime, feedValue, feedTime, feedTypeId, nitrite, nitrate, ammonia, waterTime,
            fileInputRef, selectedImg, bShowImg, feedList, imgId, imgsrc,
            
            titleColor, titleString, countCharacters, textColorClass,
            backColorClass, borderColorClass, titlePaddingStyle, inputBorderColorclass,
            inputTextColorClass, checkboxDefaultValue, onClickSave, emitNewTime, changeCycle,
            onClickUploadImg, handleFileChange, onClickDeleteImg, onClickDeleteImgsrc,
        }
    }
}
</script>

<style scoped>
    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .gro-write-record {
        width: 100%;
        height: 785px;
        border-radius: 24px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }
    .gro-wr-title {
        width: 100%;
        height: 65px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        padding-left: 33px;
        padding-right: 14px;
    }
    .gro-wr-title > div {
        font-family: "PreBold";
        font-size: 20px;
        color: #fff;
    }
    .gro-wr-title-btn {
        width: 105px;
        height: 41px;
        border-radius: 5px;
        background-color: #fff;
        font-family: "PreBold";
        font-size: 16px;
        border: none;
    }
    .gro-wr-datebox {
        width: 100%;
        padding: 0 34px;
        display: flex;
        justify-content: flex-start;
    }
    .gro-write-grid {
        width: 730px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 22px;
        column-gap: 20px;
    }
    .gro-write-content {
        width: 100%;
        height: 100%;
        padding: 30px 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .gro-write-right {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: left;
    }
    .gro-write-right > div > div {
        font-family: "PreMedium";
        font-size: 18px;
    }
    .gro-write-textarea {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        resize: none;
        border: 1px solid rgba(143, 110, 110, 0.24);
        padding: 15px;
    }
    .gro-write-textarea-box {
        width: 350px;
        height: 275px;
        position: relative;
        margin-top: 10px;
    }
    .gro-write-textarea-box > div {
        position: absolute;
        bottom: 10px;
        right: 20px;
        font-family: "PreRegular";
        font-size: 14px;
    }
    .gro-write-imgbox {
        width: 350px;
        height: 275px;
        border-radius: 6px;
        border: 1px solid rgba(143, 110, 110, 0.24);
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .gro-write-imgbox > div {
        font-family: "PreRegular";
        font-size: 15px;
        color: #4E3535;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .gro-write-imgbox > div > img {
        width: 34px;
        height: 34px;
        object-fit: cover;
        margin-bottom: 12px;
    }
    .gro-write-imgbox-img {
        width: 350px;
        height: 275px;
        border-radius: 6px;
        border: 1px solid rgba(143, 110, 110, 0.24);
        margin-top: 10px;
    }
    .gro-write-imgbox-img > img {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
    }
    .gro-record-box {
        width: 355px;
        height: 308px;
        border-radius: 17px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid;
    }
    .gro-record-input {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 41px;
        line-height: 20px;
    }
    .gro-record-input-chk {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 41px;
    }
    .gro-record-input-chk label {
        font-family: "PreBold";
        font-size: 16px;
        color: #fe3f60;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .gro-record-input-chk input {
        width: 17px;
        height: 17px;
        border-radius: 6px;
        accent-color: #fe3f60;
        margin-right: 2px;
    }
    .gro-record-input-chk > div {
        text-align: left;
        font-family: "PreMedium";
        font-size: 18px;
        margin-right: 26px;
    }
    .gro-record-input input {
        width: 200px;
        height: 41px;
        border-radius: 6px;
        border: 1px solid;
        padding-left: 50px;
        padding-right: 50px;
        text-align: center;
        font-family: "PreRegular";
        font-size: 15px;
    }
    .gro-record-input > div:nth-child(1) {
        font-family: "PreMedium";
        font-size: 18px;
    }
    .gro-record-input > div > span {
        font-family: "PreMedium";
        font-size: 15px;
    }
    .gro-record-colunm {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .gro-record-unit {
        position: absolute;
        right: 15px;
        font-family: "PreRegular";
        font-size: 15px;
    }
    .gro-record-title {
        font-family: "PreBold";
        font-size: 18px;
        text-align: left;
        width: 100%;
    }
    .gro-record-title-divider {
        width: 100%;
        height: 1px;
        background-color: #ead4d8;
    }
    .gro-record-select-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "PreBold";
        font-size: 18px;
        color: #353b55;
        white-space: nowrap;
    }
    #feed-list {
        font-family: "PreRegular";
        font-size: 15px;
        color: #485971;
        width: 200px;
        height: 41px;
        border-radius: 6px;
        border: 1px solid;
        margin-left: 9px;
        padding: 0 13px;
        background-color: #fff;
    }
    .gro-write-img-file {
        display: none;
    }
    .gro-write-title-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    .img-delete-btn {
        width: 50px;
        height: 30px;
        border-radius: 5px;
        border: none;
        background-color: #fe3f60;
        color: #fff;
        font-family: "PreRegular";
        font-size: 15px;
    }
    @media screen and (max-width: 1300px) {
        .gro-write-record {
            width: 100%;
            height: 1100px;
            border-radius: 24px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
        }
        .gro-write-content {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .gro-write-grid {
            width: 730px;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .gro-write-right {
            width: 800px;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            text-align: left;
            margin-top: 25px;
        }
    }
    @media screen and (max-width: 850px) {
        .gro-write-record {
            margin: 0 auto;
            width: 95%;
            height: 2100px;
            border-radius: 24px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
        }
        .gro-write-content {
            width: 100%;
            height: 100%;
            padding: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .gro-write-grid {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .gro-record-box {
            width: 355px;
            height: 308px;
            border-radius: 17px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid;
        }
        .gro-write-right {
            width: 100%;
            height: 700px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            text-align: left;
            margin-top: 0px;
        }
        .gro-write-right > div > div {
            font-family: "PreMedium";
            font-size: 18px;
        }
    }
</style>