import { useHttp } from '@/utils/http.js';

export default {
    namespaced: true,
    state: {
        token: localStorage.getItem('user-token') || '',
        name: localStorage.getItem('user-name') || '',
        status: '',
    },
    getters: {
        isAuthenticated: state => (!!state.token && state.token.length>0),
        authStatus: state => state.status,
        lastToken: state => state.token,
        userName: state => state.name,
    },
    mutations : {
        ['AUTH_REQUEST']: (state) => {
            state.status = 'loading'
        },
        ['AUTH_SUCCESS']: (state, token) => {
            state.status = 'success';
            state.token = token;
        },
        ['AUTH_USER_NAME']: (state, name) => {
            state.name = name;
        },
        ['AUTH_ERROR']: (state) => {
            state.status = 'error';
            state.token = '';
            state.name = '';
        },
        ['AUTH_LOGOUT']: (state) => {
            state.status = '';
            state.token = '';
            state.name = '';
        }
    },
    actions: {
        ['AUTH_REQUEST']: async ({commit, /*dispatch*/}, userData) => {
            commit('AUTH_REQUEST');
            const http = useHttp();
            const res = await http.loginAPI(userData.login_id, userData.password);
            console.log(res);
            if(res != null && res.user && res.access_token !== ''){
                const token = res.access_token;
                commit('AUTH_SUCCESS', token);
                localStorage.setItem('user-token', token);
                localStorage.setItem('user-name', res.user.name);
                http.setJWTToken(token);
                return true;
            }else{
                commit('AUTH_ERROR');
                localStorage.removeItem('user-token');
                localStorage.removeItem('user-name');
                http.removeJWTToken();
                console.log('여기로 들어오면 오류임');
            }
            return false;
        },
        ['AUTH_LOGOUT']: async ({commit,}) => {
            const http = useHttp();
            const ret = await http.logoutAPI();
            // const ret = true;
            console.log(ret);
            commit('AUTH_LOGOUT');
            localStorage.removeItem('user-token');
            localStorage.removeItem('user-name');
            localStorage.removeItem('user-phone');
            http.removeJWTToken();
            return ret;
        },
    }
}