import axios from '@/axios.js';
import { ref } from 'vue';

export const useHttp = () => {
    const tempUrl = ref('');
    const bLoading = ref(false);
    const callAPI = async ({url, params, method = 'POST'}) => {
        // 로딩중에 똑같은 callAPI가 요청되면 return;
        if (tempUrl.value === url && bLoading.value) {
            return null;
        }
        bLoading.value = true;
        tempUrl.value = url;
        try {
            let res = null;
            if (method === 'POST') {
                res = await axios.post(url, params);
            } else if (method === 'GET') {
                res = await axios.get(url, {params: params});
            }
            
            if (res && res.data && (res.data.success === 'token_error' || res.data.error_string === 'NOT AUTHORIZED')) {
                // alert('로그인 세션이 만료되었습니다.');
                localStorage.removeItem('user-token');
                removeJWTToken();
            }
            bLoading.value = false;
            return res && res.data;
        } catch(err) {
            console.log("callAPI Error:", err);
            bLoading.value = false;
        }
        return null;
    }

    const callUpload = async ({url, formData, method = 'POST'}) => {
        // 로딩중에 똑같은 callAPI가 요청되면 return;
        if (tempUrl.value === url && bLoading.value) {
            return null;
        }
        bLoading.value = true;
        tempUrl.value = url;
        try {
            let res = null;
            if (method === 'POST') {
                res = await axios.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                });

                if (res && res.data && (res.data.success === 'token_error' || res.data.error_string === 'Unauthorized')) {
                    alert('로그인 세션이 만료되었습니다.');
                    localStorage.removeItem('user-token');
                    removeJWTToken();
                }
            }
            bLoading.value = false;
            return res && res.data;
        } catch (err) {
            console.log(err);
            bLoading.value = false;
        }
    }

    const loginAPI = async (loginId, pwd) => {
        return await callAPI({
            url: '/auth/login',
            params: {
                login_id: loginId,
                password: pwd,
            },
        });
    };

    const logoutAPI = async () => {
        return await callAPI({
            url: '/auth/logout',
            params: {},
        });
    };

    const setJWTToken = (token) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };

    const removeJWTToken = () => {
        delete axios.defaults.headers.common['Authorization'];
    };

    return {
        callAPI,
        setJWTToken,
        removeJWTToken,
        loginAPI,
        logoutAPI,
        callUpload,
    }
}