<template>
    <Datepicker locale="ko" v-model="timeValue" time-picker allow-prevent-default
    @update:model-value="handleTime">
            <template #dp-input="{ value }">
                <input type="text" :value="value" class="date-input" autoApply readonly
                :style="{borderColor: pBorderColor}"/>
            </template>
            <template #clear-icon=""></template>
    </Datepicker>
</template>

<script>
import { ref, watch } from 'vue';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import dayjs from 'dayjs';

export default {
    components: {
        Datepicker,
    },
    props: {
        pTimeValue: {
            type: String,
        },
        pBorderColor: {
            type: String
        },
        pTimeType: {
            type: Number,
        }
    },
    emits: ["emitNewTime"],
    setup(props, {emit}) {
        // datepicker 관련
        const timeValue = ref(null);

        watch(() => props.pTimeValue, (cur) => {
            if (cur === undefined || cur === null) {
                timeValue.value = null;
                return;
            }
            const arTimes = cur.split(":");

            timeValue.value = {
                hours: arTimes[0],
                minutes: arTimes[1]
            }
        });

        const handleTime = (newTime) => {
            if (newTime === undefined || newTime === null) {
                return;
            }
            timeValue.value = newTime;
            const hour = dayjs().hour(newTime.hours).format('HH');
            const minutes = dayjs().minute(newTime.minutes).format('mm');
            const emitNewTime = hour + ":" + minutes + ":" + "00";
            emit("emitNewTime", emitNewTime,  props.pTimeType);
        }

        return {
            timeValue, 

            handleTime,
        }
    }
}
</script>

<style scoped>
    .date-input {
        font-family: "PreRegular";
        font-size: 15px;
        color: #485971;
        width: 200px;
        height: 41px;
        border-radius: 6px;
        border: 1px solid rgba(33, 88, 166, 0.24);
        padding: 0 13px;
        background-color: #fff;
        text-align: center;
    }
</style>