<template>
    <default-nav-bar />
    <div class="body-container">
        <div class="home-title">
            <div class="home-title-str">
                연어 생산 및 사육일지 관리 시스템
            </div>
        </div>
        <div class="home-menu">
            <div class="home-menu-items">
                <div class="item-select-box">
                    <div>수조</div>
                    <select id="cistern-list" @change="changeCistern" v-model="selectCisternsId">
                        <option v-for="cistern in cisternList" :key="cistern" :value="cistern.id">
                            {{ cistern.name }}
                        </option>
                    </select>
                </div>
                <div class="item-select-box">
                    <div>조회기간</div>
                    <div>
                        <RangeDateInput :pDateValue="dateValue" @emitNewDate="changeNewDate" />
                    </div>
                </div>
            </div>
            <div class="home-menu-item-btns">
                <input type="button" class="blue-border-btn" value="엑셀저장" @click="onClickExcel()">
                <input type="button" class="blue-border-btn" value="일지작성" style="margin-left: 14px;" @click="onClickWriteRecord()">
            </div>
        </div>
        <div v-for="record in recordList" :key="record" class="mob-date-box" 
        @click="onClickWriteRecord(record.record_date)">
            {{ record.record_date }}
        </div>
        <table class="table table-bordered" style="margin-top: 26px;">
            <!-- rowspan: 세로합치기, colspan: 가로합치기 -->
            <thead>
                <tr class="table-title">
                    <th rowspan="2" colspan="2" style="width: 10%;">날짜</th>
                    <th rowspan="2">수조</th>
                    <th rowspan="1" colspan="3">사육환경</th>
                    <th rowspan="1" colspan="3">약욕(ml)</th>
                    <th rowspan="1" colspan="3">수질(mg/L)</th>
                    <th rowspan="1" colspan="3">사료급이</th>
                    <th rowspan="2" style="width: 5%;">특이사항</th>
                    <th rowspan="2" style="width: 5%;">사진</th>
                </tr>
                <tr class="table-sub-title">
                    <th>수온(°C)</th>
                    <th>사육수</th>
                    <th>폐사량</th>
                    <th>o.t.c</th>
                    <th>암옥신</th>
                    <th>옥소린</th>
                    <th>아질산염</th>
                    <th>질산염</th>
                    <th>암모니아</th>
                    <th>종류(호수)</th>
                    <th>급이량(g)</th>
                    <th>시간</th>
                </tr>
            </thead>
            <tbody>
                <GROTableContent v-for="(record, index) in recordList" :key="record" :pRecord="record" :pBackColor="index" 
                @onNoteSelected="onNoteSelected" :pNoteId="selectedNoteId" @onClickRecord="onClickWriteRecord" />
            </tbody>
        </table>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useHttp } from '@/utils/http.js';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';

import RangeDateInput from '@/components/datepicker/RangeDateInput.vue';
import GROTableContent from '@/components/GROTableContent.vue';
import DefaultNavBar from '@/components/layout/DefaultNavBar.vue';

export default {
    components: {
        RangeDateInput,
        GROTableContent,
        DefaultNavBar,
    },
    setup() {
        const http = useHttp();
        const router = useRouter();

        // 수조 selectbox 리스트
        const cisternList = ref([]);
        const selectCisternsId = ref(null);

        // 사육일지 리스트
        const recordList = ref([]);

        // datepicker 관련
        const dateValue = ref([]);

        // 선택된 note id(사실 record id임)
        const selectedNoteId = ref(null);

        const loadData = async () => {
            if (dateValue.value.length === 0) {
                dateValue.value[0] = dayjs().startOf('month');
                dateValue.value[1] = dayjs();
            }
            const params = {
                cisterns_id: parseInt(selectCisternsId.value),
                start_at: dayjs(dateValue.value[0]).format('YYYY-MM-DD'),
                end_at: dayjs(dateValue.value[1]).format('YYYY-MM-DD')
            }
            
            const resp = await http.callAPI({
                url: "/record/list",
                params: params,
                method: "POST"
            });

            if (resp && resp.success) {
                // 수조 리스트
                cisternList.value = resp.cisterns;
                selectCisternsId.value = resp.cisterns_id;
                // 날짜관련
                const dayjsStart = dayjs(resp.start_at);
                const dayjsEnd = dayjs(resp.end_at);
                dateValue.value = [dayjsStart, dayjsEnd]
                // 생육관리일지 리스트
                recordList.value = resp.records;
            } else if (resp === null) {
                return;
            } else {
                alert(resp.error_string);
            }
        }
        loadData();

        // 기존에 선택된 노트를 해제하고 새로운 노트 선택
        const onNoteSelected = (newNoteId, event) => {
            selectedNoteId.value = newNoteId;
            event.stopPropagation();
        }

        // 일지작성으로 이동
        const onClickWriteRecord = (recordDate) => {
            if (recordDate === undefined && selectCisternsId.value === undefined) {
                router.push({name: 'WriteRecord'});
            } else {
                router.push({name: 'WriteRecord', query: {
                    record_date: dayjs(recordDate).format('YYYY-MM-DD'),
                    cisterns_id: selectCisternsId.value,
                }});
            }
        }

        // 수조 변경시 이벤트 발생
        const changeCistern = async (event) => {
            selectCisternsId.value = event.target.value;
            loadData();
        }

        // 날짜 변경시 이벤트 발생
        const changeNewDate = (newDate) => {
            dateValue.value = newDate;
            loadData();
        }

        // 엑셀저장 이벤트
        const onClickExcel = () => {
            // TODO: 엑셀저장기능
            alert("기능 준비중입니다.");
        }

        return {
            cisternList, dateValue, recordList, selectedNoteId, selectCisternsId,

            onNoteSelected, onClickWriteRecord, changeCistern, changeNewDate, onClickExcel,
        }
    }
}
</script>

<style scoped>
    .body-container {
        padding: 30px 44px;
        min-height: 100vh;
    }
    .home-menu {
        width: 100%;
        height: 41px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .home-menu-items {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .home-menu-item-btns {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .item-select-box {
        min-width: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "PreBold";
        font-size: 18px;
        color: #353b55;
        white-space: nowrap;
    }
    .item-select-box:nth-child(1) {
        margin-right: 34px;
    }
    #cistern-list {
        font-family: "PreRegular";
        font-size: 15px;
        color: #485971;
        width: 170px;
        height: 41px;
        border-radius: 6px;
        border: 1px solid rgba(33, 88, 166, 0.24);
        margin-left: 9px;
        padding: 0 13px;
        background-color: #fff;
    }

    table {
      border-collapse: collapse;
      width: 100%;
    }

    table,
    th,
    td {
        border: 1px solid rgba(255, 255, 255, 0.27);
        padding: 8px;
        text-align: center;
    }

    /* 사육환경, 약욕, 수질, 사료급이 셀 너비 고정 */
    th[colspan="3"],
    th[colspan="3"] + th,
    th[colspan="3"] + th + th {
        width: 100px;
    }

    /* 셀 가로 중앙 정렬 */
    th,
    td {
        vertical-align: middle;
    }

    /* 특이사항, 사진 셀 세로 중앙 정렬 */
    th[rowspan="2"],
    td[rowspan="2"] {
        vertical-align: middle;
    }

    .table-title > th {
        font-family: "PreBold";
        font-size: 18px;
        background-color: #014099;
        color: #fff;
    }
    .table thead tr th:nth-child(7) {
        width: 100px;
    }
    .table thead tr th:nth-child(8) {
        width: 100px;
    }

    .table-sub-title > th {
        width: 100px;
        font-family: "PreMedium";
        font-size: 15px;
        color: #fff;
        background-color: #4D78B8;
    }

    .home-title {
        display: none;
    }

    .mob-date-box {
        display: none;
    }

    @media screen and (max-width: 1500px) {
        .table-title > th {
            font-family: "PreBold";
            font-size: 14px;
            background-color: #014099;
            color: #fff;
        }

        .table-sub-title > th {
            width: 100px;
            font-family: "PreMedium";
            font-size: 13px;
            color: #fff;
            background-color: #4D78B8;
            white-space: nowrap;
        }
        .blue-border-btn {
            width: 110px;
            font-size: 16px;
        }
    }
    @media screen and (max-width: 850px) {
        .body-container {
            width: 100%;
            min-height: 100vh;
            padding: 0;
            padding-bottom: 80px;
            background-color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        .home-menu {
            width: 90%;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin: 0 auto;
        }
        .home-menu-items {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
        }
        .home-menu-item-btns {
            width: 100%;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            bottom: 0;
            background: linear-gradient(rgba(33, 88, 166, 0.24), rgba(255, 255, 255, 0.24));;
        }
        .item-select-box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .item-select-box > div:nth-child(1) {
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            text-align: left;
            font-family: "PreBold";
            font-size: 15px;
            color: #353b55;
            white-space: nowrap;
        }
        .item-select-box > div:nth-child(2) {
            width: 70%;
            height: 100%;
        }
        #cistern-list {
            font-family: "PreRegular";
            font-size: 13px;
            color: #485971;
            width: 70%;
            height: 41px;
            border-radius: 6px;
            border: 1px solid rgba(33, 88, 166, 0.24);
            padding: 0;
            background-color: #fff;
            margin-bottom: 10px;
            text-align: center;
        }
        table {
            display: none;
        }
        .mob-date-box {
            width: 90%;
            height: 50px;
            border: 1px solid #DDDDDD;
            margin-bottom: 8px;
            display: block;
            font-family: "PreBold";
            font-size: 15px;
            color: #014099;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .home-title {
            width: 100%;
            height: 70px;
            background-color: #F5F6FB;
            display: block;
            margin-bottom: 12px;
        }
        .home-title-str {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "PreBold";
            font-size: 17px;
            color: #014099;
        }
    }
</style>