<template>
    <Datepicker locale="ko" v-model="dateValue" :format="dateFormat"
        :week-start="0" :enableTimePicker="false" @update:model-value="handleDate"
        :auto-position="true" position="bottom" range autoApply>
            <template #dp-input="{ value }">
                <input type="text" :value="value" class="date-input" autoApply readonly/>
            </template>
            <template #clear-icon=""></template>
    </Datepicker>
</template>

<script>
import { ref, watch } from 'vue';

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import dayjs from 'dayjs';

export default {
    components: {
        Datepicker,
    },
    props: {
        pDateValue: {
            type: Array,
        }
    },
    emits: ["emitNewDate"],
    setup(props, {emit}) {
        // datepicker 관련
        const dateValue = ref(null);
        // const startDateValue = ref(null);
        // const endDateValue = ref(null);

        const dateFormat = (selectedDates) => {
            if (selectedDates && selectedDates.length === 2) {
                const startDate = selectedDates[0];
                const endDate = selectedDates[1];
                const startFormatted = dayjs(startDate).format("YYYY-MM-DD");
                const endFormatted = dayjs(endDate).format("YYYY-MM-DD");
                return startFormatted + ' ~ ' + endFormatted;
            } else {
                return "";
            }
        };

        watch(() => props.pDateValue, (newDate) => {
            dateValue.value = newDate;
        })

        const handleDate = (modelData) => {
            dateValue.value = modelData;
            emit("emitNewDate", dateValue.value);
        }

        return {
            dateValue, 

            dateFormat, handleDate,
        }
    }
}
</script>

<style scoped>
    .date-input {
        font-family: "PreRegular";
        font-size: 15px;
        color: #485971;
        width: 215px;
        height: 41px;
        border-radius: 6px;
        border: 1px solid rgba(33, 88, 166, 0.24);
        margin-left: 9px;
        padding: 0 13px;
        background-color: #fff;
        text-align: center;
    }
    @media screen and (max-width: 850px) {
        .date-input {
            font-family: "PreRegular";
            font-size: 13px;
            color: #485971;
            margin-left: 0px;
            width: 100%;
            height: 41px;
            border-radius: 6px;
            border: 1px solid rgba(33, 88, 166, 0.24);
            padding: 0 13px;
            background-color: #fff;
            text-align: center;
        }
    }
</style>