import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store.js'

// 로그인
import Login from '@/views/LoginView.vue';

// 생육관리 조회
import HomeView from '@/views/HomeView.vue';

// 생육관리 작성
import WriteRecord from '@/views/WriteRecordView.vue';

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters['auth/isAuthenticated']) {
        next();
        return;
    }
    next('/');
};
// 인증이 된 사람 통과, 안된사람은 login 페이지로 이동
const ifAuthenticated = (to, from, next) => {
    if (store.getters['auth/isAuthenticated']) {
        next()
        return;
    }
    next('/login');
};

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/write',
        name: 'WriteRecord',
        component: WriteRecord,
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: ifNotAuthenticated,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});
// router.beforeEach((to, from, next) => {
//     if(to.name === 'LoginView'){
//         window.callWebKitFunction({name: 'willMoveTo', value: to.name});
//     }else if(from.name === 'LoginView'){
//         window.callWebKitFunction({name: 'willMoveFrom', value: from.name});
//     }
//     next();
// })
export default router;