<template>
    <default-nav-bar />
    <div class="body-container">
        <div class="write-record-container">
            <div class="gro-wr-datebox">
                <div class="write-record-select-box">
                    <div>날짜</div>
                    <div>
                        <SelectDateInput :pDateValue="dateValue" @emitNewDate="changeNewDate" />
                    </div>
                </div>
                <div class="write-record-select-box">
                    <div>수조</div>
                    <select id="cistern-list" @change="changeCistern($event)" v-model="selectCisternId">
                        <option v-for="cistern in cisternList" :key="cistern" :value="cistern.id">
                            {{ cistern.name }}
                        </option>
                    </select>
                </div>
            </div>
            <GROWriteRecord :pRecordType="'mor'" :pRecords="recordMorObj" :pFeeds="feedList"
            @emitSave="onClickSave" :pSelectedDate="dateValue" @emitDelImg="onClickDeleteImgsrc"/>
            <div style="height: 50px;"></div>
            <GROWriteRecord :pRecordType="'aft'" :pRecords="recordAftObj" :pFeeds="feedList" 
            @emitSave="onClickSave" :pSelectedDate="dateValue" @emitDelImg="onClickDeleteImgsrc"/>
            <div style="height: 50px;"></div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import dayjs from 'dayjs';
import { useHttp } from '@/utils/http.js';
import { useRoute } from 'vue-router';

import GROWriteRecord from '@/components/GROWriteRecord.vue';
import SelectDateInput from '@/components/datepicker/SelectDateInput.vue';
import DefaultNavBar from '@/components/layout/DefaultNavBar.vue';

export default {
    components: {
        GROWriteRecord,
        SelectDateInput,
        DefaultNavBar,
    },
    setup() {
        const http = useHttp();
        const route = useRoute();

        // datepicker 관련
        const dateValue = ref(route.query.record_date);
        // 일지 내용
        const recordMorObj = ref({});
        const recordAftObj = ref({});
        // 수조 리스트
        const cisternList = ref([]);
        const selectCisternId = ref(parseInt(route.query.cisterns_id));
        // 사료 리스트
        const feedList = ref([]);

        const loadData = async () => {
            if (dateValue.value === null) {
                dateValue.value = dayjs().format('YYYY-MM-DD');
            }
            const params = {
                cisterns_id: parseInt(selectCisternId.value),
                date_at: dateValue.value,
            }
            const resp = await http.callAPI({
                url: "/record/content",
                params: params,
                method: "POST"
            });

            // console.log("응답결과는?", resp);

            if (resp && resp.success) {
                // 수조 리스트
                cisternList.value = resp.cisterns;
                selectCisternId.value = resp.cisterns_id;
                // 날짜관련
                dateValue.value = resp.date_at;
                // 생육관리일지 리스트
                if (resp.records) {
                    if (resp.records.morning) {
                        recordMorObj.value = resp.records.morning;
                    } else {
                        recordMorObj.value = null;
                    }
                    if (resp.records.afternoon) {
                        recordAftObj.value = resp.records.afternoon;
                    } else {
                        recordAftObj.value = null;    
                    }
                } else {
                    recordMorObj.value = null;
                    recordAftObj.value = null;
                }
                // 사료 리스트
                feedList.value = resp.feeds;
            } else if (resp === null) {
                return;
            } else {
                alert(resp.error_string);
            }
        }
        loadData();

        const onClickSave = async (emitRecord, recordType, imgFile) => {
            if (recordType === 'mor') {
                if (recordMorObj.value && recordMorObj.value.id) {
                    emitRecord.id = parseInt(recordMorObj.value.id);
                }
                emitRecord.record_type = 0;
            } else if (recordType === 'aft') {
                if (recordAftObj.value && recordAftObj.value.id) {
                    emitRecord.id = parseInt(recordAftObj.value.id);
                }
                emitRecord.record_type = 1;
            }
            emitRecord.record_date = dateValue.value;
            emitRecord.cisterns_id = parseInt(selectCisternId.value);

            console.log("세이브 데이터는?", emitRecord, recordType, imgFile, dateValue.value);
            
            const formData = new FormData();
            formData.append("img_file", imgFile);
            formData.append("record", JSON.stringify(emitRecord));

            const resp = await http.callUpload({
                url: "/record/create",
                formData: formData,
                method: "POST"
            });

            if (resp && resp.success) {
                alert("일지가 저장되었습니다.");
                if (resp.records) {
                    if (resp.records.morning) {
                        recordMorObj.value = resp.records.morning;
                    } else {
                        recordMorObj.value = null;
                    }
                    if (resp.records.afternoon) {
                        recordAftObj.value = resp.records.afternoon;
                    } else {
                        recordAftObj.value = null;    
                    }
                } else {
                    recordMorObj.value = null;
                    recordAftObj.value = null;
                }
            } else if (resp === null) {
                return;
            } else {
                alert(resp.error_string);
            }
        }

        const onClickDeleteImgsrc = async (imgId) => {
            const resp = await http.callAPI({
                url: "/record/delimg",
                params: {
                    img_id: parseInt(imgId),
                },
                method: "POST"
            });

            if (resp && resp.success) {
                loadData();
            } else if (resp === null) {
                return;
            } else {
                alert(resp.error_string);
            }
        }

        // 수조 변경시 이벤트 발생
        const changeCistern = (event) => {
            selectCisternId.value = event.target.value;
            loadData();
        }

        // 날짜 변경시 이벤트 발생
        const changeNewDate = (newDate) => {
            dateValue.value = dayjs(newDate).format('YYYY-MM-DD');
            loadData();
        }

        return {
            dateValue, cisternList, recordMorObj, recordAftObj,
            feedList, selectCisternId,

            onClickSave, changeCistern, changeNewDate, onClickDeleteImgsrc
        }
    }
}
</script>

<style scoped>
    .body-container {
        height: 100%;
    }
    .write-record-container {
        width: 1200px;
        height: 100%;
        padding-top: 46px;
        margin: 0 auto;
    }
    .gro-wr-datebox {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
    }
    .write-record-select-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: "PreBold";
        font-size: 18px;
        color: #353b55;
        white-space: nowrap;
        margin-right: 34px;
    }
    #cistern-list {
        font-family: "PreRegular";
        font-size: 15px;
        color: #485971;
        width: 170px;
        height: 41px;
        border-radius: 6px;
        border: 1px solid rgba(33, 88, 166, 0.24);
        margin-left: 9px;
        padding: 0 13px;
        background-color: #fff;
    }
    @media screen and (max-width: 1300px) {
        .write-record-container {
            width: 800px;
            height: 100%;
            padding-top: 46px;
            margin: 0 auto;
        }
    }
    @media screen and (max-width: 850px) {
        .body-container {
            width: 100%;
            min-height: 100vh;
            padding: 0;
            padding-bottom: 80px;
            background-color: #F5F6FB;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        .write-record-container {
            width: 100%;
            height: 100%;
            padding-top: 26px;
            margin: 0 auto;
        }
        .gro-wr-datebox {
            width: 100%;
            height: 93px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 30px;
        }
        .write-record-select-box {
            width: 90%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: "PreBold";
            font-size: 18px;
            color: #353b55;
            white-space: nowrap;
            margin-right: 0px;
        }
        .write-record-select-box > div:nth-child(1) {
            width: 30%;
        }
        .write-record-select-box > div:nth-child(2) {
            width: 70%;
        }
        #cistern-list {
            font-family: "PreRegular";
            font-size: 15px;
            color: #485971;
            width: 70%;
            height: 41px;
            border-radius: 6px;
            border: 1px solid rgba(33, 88, 166, 0.24);
            margin-left: 0px;
            padding: 0 13px;
            background-color: #fff;
            text-align: center;
        }
    }
</style>