import axios from 'axios';
const baseURL = process.env.NODE_ENV == 'development' ? 'http://localhost:1323/api' : 'https://www.gbgro.com:444/api';
var ax = axios.create({baseURL: baseURL})
ax.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
ax.defaults.headers.common['Content-Type'] = 'application/json';
const lastJwt = localStorage.getItem('user-token');
if(lastJwt){
    ax.defaults.headers.common['Authorization'] = `Bearer ${lastJwt}`;
}

export default ax;